import appConfig from '../app-config'
export default class MainController {
	readonly apiHost: string
	readonly certificateApiPath: string

	constructor () {
		this.apiHost = appConfig.hostname
		this.certificateApiPath = appConfig.paths.apiPathCertificate
	}

	async getHeaders (method: string, file?: boolean, fileName?: any, impersonateKerb?: string): Promise<Headers> {
		let headers: Headers = new Headers()

		if (file) {
			// headers.append('Content-Type', 'binary/octet-stream');
			// headers.append('Content-Filename', fileName);
		} else {
			headers.append('Content-Type', 'application/json')
			//headers.append('Cache-Control', 'private, max-age=3600');
		}

		if (impersonateKerb) {
			headers.append('X-Impersonated-User', impersonateKerb)
		}

		return headers
	}

	async useFetch (
		method: string,
		fetchKey: string,
		url: string,
		body?: any,
		file?: any,
		fileName?: string | null,
		impersonateKerb?: string
	): Promise<any> {
		let options: any = {
			method: method,
			headers: this.getHeaders(method, file, fileName, impersonateKerb)
		}
		if (body) {
			options['body'] = file ? body : JSON.stringify(body)
		}

		let response = await fetch(url, options)

		//check for SCP timeout
		let sessionExpiredHeader = response.headers.get('com.sap.cloud.security.login')

		if (sessionExpiredHeader) {
			//show reload dialog
			console.error('SESSION EXPIRED!', 'RELOAD')
			window.location.reload()
		}

		return response
	}
}
