import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { AtlasPage, BasicLayout } from '@mit/hui'
import CertificateVerificationComponent from './components/CertificateVerification'

const App: React.FC = () => {
	return (
		<Router>
			<AtlasPage
				gaId='G-JYM733WKSZ'
				name='MIT Medical Covid-19'
				subtitle='Proof Of Vaccination'
				theme='default'
				inverseHeading={false}
				// environment={getEnv()}
				customLogo={
					<div className='row m-2'>
						<svg width='101px' height='53px' viewBox='0 0 101 53' version='1.1'>
							<g stroke='none' fill='none'>
								<g id='Group-2'>
									<polygon id='Fill-2' fill='#ffffff' points='54 11 65 11 65 1 54 1' />
									<polygon id='Fill-3' fill='#ffffff' points='72 53 83 53 83 17 72 17' />
									<polygon id='Fill-4' fill='#ffffff' points='0 53 11 53 11 1 0 1' />
									<polygon id='Fill-5' fill='#ffffff' points='36 53 47 53 47 1 36 1' />
									<polygon id='Fill-6' fill='#ffffff' points='18 36 29 36 29 0 18 0' />
									<polygon id='Fill-7' fill='#ffffff' points='72 11 101 11 101 1 72 1' />
									<polygon id='Fill-42' fill='#b68791' points='54 53 65 53 65 17 54 17' />
								</g>
							</g>
						</svg>
					</div>
				}
				content={
					<Switch>
						<Route path='/:code?'>
							<BasicLayout
								contentTitle='Proof Of Vaccination'
								startState
								content={<CertificateVerificationComponent />}
							/>
						</Route>
					</Switch>
				}
			/>
		</Router>
	)
}

export default App
