import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { Textbox, Container, Icon, TextboxType } from '@mit/hui'
import { useParams, useHistory } from 'react-router'
import API from '../api/CertificateVerificationApi'

interface ParamTypes {
	code: string
}

interface certificateDataModel {
	available: boolean
	valid: boolean
	certificate_number: string
	lot_company: string
	course_guid: string
	first_shot_date: string
	second_shot_date: string
	location: string
	error: string | null
}

const CertificateVerification: FC = () => {
	const certificateDataDefault = {
		available: false,
		first_shot_date: '',
		second_shot_date: '',
		valid: false,
		certificate_number: '',
		lot_company: '',
		course_guid: '',
		location: '',
		error: null
	}

	const history = useHistory<any>()
	// const pause = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
	const { code } = useParams<ParamTypes>()
	const [certificateCode, setSertificateCode] = useState<string>(code || '')
	const [certificateData, setSertificateData] = useState<certificateDataModel>({ ...certificateDataDefault })

	const [isLoading, setIsLoading] = useState<boolean>(true)

	const formatDate = (date: string) => {
		const d = new Date(date)
		const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
		const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
		const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
		return `${mo} ${da}, ${ye}`
	}

	const updateURL = (path: string) => {
		if (path) history.push(`/${path}`)
		else history.push(`/`)
	}
	const handleKeyDown = (event: any) => {
		if (event.key === 'Enter') {
			doLookup(event.target.value)
		} else {
			setSertificateData({ ...certificateDataDefault })
		}
	}

	const doLookup = async (value: string) => {
		setSertificateData({ ...certificateDataDefault })

		setIsLoading(true)
		// await pause(500);
		let res = await API.verifyCertificate(certificateCode)
		console.log('FETCH:: doLookup RES', res)
		if (res.status) {
			setSertificateData({ ...res.data, ...{ available: true, valid: true } })
		} else {
			if (res.error) {
				setSertificateData({ ...certificateDataDefault, ...{ available: true, valid: false, error: res.errorMsg } })
			}
			if (res.serverError) {
				setSertificateData({ ...certificateDataDefault, ...{ available: false, valid: false, error: res.errorMsg } })
			}
		}
		setIsLoading(false)
	}

	useEffect(() => {
		if (certificateCode) doLookup(certificateCode)
		else setIsLoading(false)
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<div className={'certificate-validation-container'}>
				<div className={`container`}>
					<div className={'row justify-content-sm-center'}>
						<div className={'col-xs-12 col-md-6 col-lg-4 '}>
							<Textbox
								name='certificate_code'
								placeholderText='Certificate Code'
								value={certificateCode}
								isBusy={isLoading}
								disabled={isLoading}
								compact={false}
								endIconRequired={true}
								startIcon='search'
								type={TextboxType.IconStart | TextboxType.IconEnd}
								onEndIconClick={(e: any) => {
									doLookup(certificateCode)
								}}
								endIcon={'search'}
								onKeyUp={(e: any) => {
									updateURL(e.target.value.trim())
									setSertificateCode(e.target.value.trim())
									handleKeyDown(e)
								}}
							/>
						</div>
					</div>

					<div className={'row justify-content-sm-center'}>
						<div className={'col-xs-12 col-md-6 col-lg-4 '}>
							{certificateData.available ? (
								<div className={'contaier mt-3'}>
									<Container
										className={`certificate-card ${!!certificateData.valid ? 'valid' : 'invalid'}`}
										contents={
											!!certificateData.valid ? (
												<div className={'contaier m-4 text-center'}>
													<div className='bigicon '>
														<Icon type={'regular'} icon='shield-check' padded={false} />
													</div>

													<div>
														<h3>VALID</h3>
														<span>Vaccine Certificate</span>
													</div>
													<div className={'contaier'}>
														<div className={'row'}>
															<div className={'col text-left'}>First Shot</div>
															<div className={'col text-right date'}>{formatDate(certificateData.first_shot_date)}</div>
														</div>
														<hr />
														<div className={'row'}>
															<div className={'col text-left'}>Second Shot</div>
															<div className={'col text-right date'}>
																{formatDate(certificateData.second_shot_date)}
															</div>
														</div>
													</div>
												</div>
											) : (
												<div className={'contaier m-4 text-center'}>
													<div className='bigicon'>
														<Icon type={'regular'} icon='times' padded={false} />
													</div>
													<h3>INVALID</h3>
													<span>Vaccine Certificate</span>
													<hr />
												</div>
											)
										}
										alignment='center'
										raised={true}
									/>
								</div>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CertificateVerification
