export interface State {
	isLoading: boolean
	appText: any
}

export interface GlobalModel extends State {}

export const globalModel: GlobalModel = {
	isLoading: true,
	appText: undefined
}
