import BaseController from '../api/BaseController'

class CovidCertificateVerificationApi extends BaseController {
	readonly url_verify_certificate: string
	constructor () {
		super()
		this.url_verify_certificate = `${this.apiHost}/${this.certificateApiPath}/certificate?cert=`
	}

	verifyCertificate = async (certificateCode: string): Promise<any> => {
		let url = `${this.url_verify_certificate}${certificateCode}`

		try {
			let response = await this.useFetch('GET', 'verifyCertificate', url)

			if (response.status === 200) {
				let data = await response.json()
				return {
					status: 1,
					data
				}
			} else {
				return {
					status: 0,
					errorMsg: response.message,
					error: true
				}
			}
		} catch (e) {
			return {
				status: 0,
				serverError: true,
				errorMsg: e.message
			}
		}
	}
}

const covidCertificateVerificationApi = new CovidCertificateVerificationApi()
export default covidCertificateVerificationApi
